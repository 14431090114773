@import "@/assets/scss/var.scss";
.orders {
    font-size: 14px;
    .header {
        color: #999;
        display: flex;
        align-items: center;
        background: #f7f8fa;
        padding: 8px 14px;
        div:first-child {
            margin-right: 10px;
        }
    }
    .el-alert{
        .el-alert__content {
            padding: 0;
        }
    }
    .odList {
        .item + .item {
            margin-top: 20px;
        }
    }
    &-contont {
        display: flex;
        // align-items: center;
        text-align: center;
        > div {
            flex: 1;
            text-align: center;
            border-right: 1.5px solid #f7f8fa;
            border-bottom: 1.5px solid #f7f8fa;
        }
        > div:first-child {
            border-left: 1.5px solid #f7f8fa;
        }
        .orders-goods {
            flex: 4;
            padding: 10px;
            .goods-item .row .item__left-img {
                width: 50px;
                height: 50px;
                min-width: 50px;
            }
            .goods-item .row .item__price-sell {
                color: #000;
            }
            .amt {
                text-align: right;
                > span {
                    font-size: 16px;
                    color: $color-error;
                }
            }
        }
    }
    &-state,&-btns {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
    &-detail {
        color: $color-primary;
        cursor: pointer;
        margin-top: 5px;
        >a{
            display: inline-block;
        }
    }
    &-detail:after {
        content: "";
        display: inline-block;
        border-style: solid;
        border-width: 5px;
        border-color: transparent transparent transparent $color-primary;
        margin-left: 5px;
    }
}
